<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" dayDateWithBackBtn="true" customer="true" />

    <v-row>
      <v-col md="12">
        <h1>DAILY FEEDBACK</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <v-checkbox
          v-model="dailyFeedback.dailyUpdateCustomer"
          label="Daily Update with Customer?"
        ></v-checkbox>
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          label="Est Customer Satisfaction"
          :items="satisfactionValues"
          v-model="dailyFeedback.customerSatisfaction"
        ></v-select>
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          label="Project On time?"
          :items="projectOnTimeValues"
          v-model="dailyFeedback.projectOnTime"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <v-checkbox
          v-model="dailyFeedback.vehicleMaintenance"
          label="Vehicle Maintenance OK?"
        ></v-checkbox>
      </v-col>

      <v-col cols="12" md="3">
        <v-checkbox
          v-model="dailyFeedback.equipmentMaintenance"
          label="Equipment Maintenance OK?"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-textarea
          v-model="dailyFeedback.comments"
          outlined
          label="Comments"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn color="primary" dark @click="dialog = true" class="mr-2"
          >Add
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1 cursor-pointer"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="canput === true"
          color="warning"
          small
          class="mr-5"
          @click="editedItems(item)"
          >fas fa-edit</v-icon
        >
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-container>
          <div class="popup-wrap">
            <div class="d-flex justify-space-between">
              <div class="popup-title">
                <h6>{{ formTitle }}</h6>
              </div>

              <v-btn text @click="close">
                <v-img
                  src="../../assets/close-icon.png"
                  alt="close-icon"
                ></v-img>
              </v-btn>
            </div>

            <div class="popup-wrap-inner step-one">
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-row class="px-5">
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Nick Name"
                            required
                            v-model="editedItem.nickName"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <label>Rating</label>
                          <v-rating
                            v-model="editedItem.rating"
                            background-color="indigo lighten-3"
                            color="indigo"
                            medium
                          ></v-rating>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Comments"
                            required
                            v-model="editedItem.comment"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <div class="text-right">
                            <v-btn color="primary" dark @click="saveFormData">
                              {{ formButtonTitle }}
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";

export default {
  name: "TodayFeedback",
  components: { CustomerInfo },
  data() {
    return {
      dialog: false,
      valid: false,
      editedIndex: -1,
      headers: [
        { text: "Nick Name", value: "nickName" },
        { text: "Rating", value: "rating" },
        { text: "Comment", value: "comment" },
        { text: "Actions", value: "actions" },
      ],
      items: [
        {
          nickName: "Sopa",
          rating: 2,
          comment: "",
        },
        {
          nickName: "Chalupa",
          rating: 5,
          comment: "",
        },
        {
          nickName: "Samuel",
          rating: 1,
          comment: "",
        },
        {
          nickName: "Angel",
          rating: 3,
          comment: "",
        },
      ],
      editedItem: {
        nickName: "",
        rating: null,
        comment: "",
      },
      projectOnTimeValues: [
        "Very Behind",
        "Behind",
        "On Time",
        "Ahead",
        "Very Ahead",
      ],
      satisfactionValues: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      dailyFeedback: {
        customerSatisfaction: "",
        dailyUpdateCustomer: "",
        projectOnTime: "",
        vehicleMaintenance: "",
        equipmentMaintenance: "",
        comments: "",
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Feedback" : "Update Feedback";
    },
    formButtonTitle() {
      return this.editedIndex === -1 ? "Add" : "Update";
    },
  },

  methods: {
    editedItems(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    resetItems() {
      this.editedItem = {
        nickName: null,
        rating: null,
        comment: null,
      };
    },
    saveFormData() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
          this.resetItems();
        } else {
          this.items.push(this.editedItem);
          this.resetItems();
          this.reset = true;
        }
        this.close();
      }
    },
    close() {
      this.resetItems();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style scoped>
.popup-title {
  padding: 0px 0px 0px 18px !important;
  text-align: center;
}

.popup-wrap {
  padding: 20px 0;
}
</style>
